import {ImageProps} from '@lookiero/aurora/build/components/primitives/Image/Image';

export enum Brand {
  VILA,
  ONLY,
  ICHI,
  VERO_MODA,
  GRACE_MILA,
  NAF_NAF,
  SCOTCH_SODA,
  IKKS,
  DIXIE,
  JOULES,
  CARMAKOMA,
  ESPRIT,
  MOSS_COPENHAGEN,
  TIFFOSI,
  LEE,
  FRENCH_CONNECTION,
  PIECES,
  FATFACE,
  STREET_ONE,
  SOFT_REBELS,
  CKS,
  GARCIA_JEANS,
  B_YOUNG,
  FREEQUENT,
  JUNAROSE,
  SILVIAN_HEACH,
  SOAKEDINLUXURY,
  HOBBS,
  WHISTLES,
  REPLAY,
  BROADWAY,
  CECIL,
  TOMTAILOR,
  LEVIS,
  TAMARIS,
  SAMSOE_SAMSOE,
  SELECTED_FEMME,
  MBYM,
  NUMPH,
  VAGABOND,
  NAKD,
  LINDEX,
  GERRY_WEBER,
  SOYACONCEPT,
  SAINT_TROPEZ,
}

export interface BrandDef {
  name: string;
  image: ImageProps;
}
