export const IDS = {
  SECTION: 'inside',
  TITLE: 'inside-title',
  SUB_TITLE: 'inside-subtitle',
  BODY: 'inside-body',
  IMAGE: 'inside-image',
} as const;

export const TEXT = {
  HEADER: {id: 'home.additionalinfo1.header'},
  TITLE: {id: 'home.additionalinfo1.claim'},
  BULLET1_TITLE: {id: 'home.additionalinfo1.bullet1'},
  BULLET1_TEXT: {id: 'home.additionalinfo1.text1'},
  BULLET2_TITLE: {id: 'home.additionalinfo1.bullet2'},
  BULLET2_TEXT: {id: 'home.additionalinfo1.text2'},
  BULLET3_TITLE: {id: 'home.additionalinfo1.bullet3'},
  BULLET3_TEXT: {id: 'home.additionalinfo1.text3'},
} as const;
