import React, {FC} from 'react';
import {SPACE, Text, TitleIcon, useDevice} from '@lookiero/aurora';
import {Section} from '@/components/atoms/Section';
import {useIntl} from '@/modules/I18N';
import {useFaq} from '@/pages/Home/hooks/useFaq';

import {IDS, TEXT} from './FaqSection.definition';

import {FaqAccordion} from './FaqAccordion';

const Faq = () => {
  const {formatMessage} = useIntl();
  const {screen} = useDevice();
  return (
    <Section nativeID={IDS.SECTION}>
      <TitleIcon text={formatMessage(TEXT.TITLE)} icon="double_semicircle" />
      <Text heading level={screen.L ? 2 : 3} marginTop={SPACE.SPACE_4} marginBottom={SPACE.SPACE_8}>
        {formatMessage(TEXT.SUBTITLE)}
      </Text>
      <FaqAccordion />
    </Section>
  );
};

const FaqSection: FC = () => {
  const {enabled, isLoading} = useFaq();

  return enabled && !isLoading ? <Faq /> : <></>;
};

const MemoFaqSection = React.memo(FaqSection);
export {MemoFaqSection as FaqSection};
