import {IMAGES_URL} from '@/config';

import {Brand, BrandDef} from '../../types/Brand';

const ASSET_URL = `${IMAGES_URL}/brands`;

export const IDS = {
  MODAL_ID: 'brands-modal',
} as const;

export const TEXT = {
  MODAL_TITLE: {id: 'home.brandsmodal.title', defaultMessage: 'Our brands'},
  MODAL_DESCRIPTION: {
    id: 'home.brandsmodal.subtitle',
    defaultMessage: 'We work with over 150 European brands such as…',
  },
} as const;

export const BRANDS_DEF: Record<Brand, BrandDef> = {
  [Brand.VILA]: {
    name: 'Vila',
    image: {
      src: `${ASSET_URL}/vila.png`,
      srcSet: `${ASSET_URL}/vila.png 1x, ${ASSET_URL}/vila_2x.png 2x`,
    },
  },
  [Brand.ONLY]: {
    name: 'Only',
    image: {
      src: `${ASSET_URL}/Only.png`,
      srcSet: `${ASSET_URL}/Only.png 1x, ${ASSET_URL}/Only_2x.png 2x`,
    },
  },
  [Brand.ICHI]: {
    name: 'Ichi',
    image: {
      src: `${ASSET_URL}/ICHI.png`,
      srcSet: `${ASSET_URL}/ICHI.png 1x, ${ASSET_URL}/ICHI_2x.png 2x`,
    },
  },
  [Brand.VERO_MODA]: {
    name: 'Vero moda',
    image: {
      src: `${ASSET_URL}/Vero_moda.png`,
      srcSet: `${ASSET_URL}/Vero_moda.png 1x, ${ASSET_URL}/Vero_moda_2x.png 2x`,
    },
  },
  [Brand.GRACE_MILA]: {
    name: 'Grace&Mila',
    image: {
      src: `${ASSET_URL}/Grace&Mila.png`,
      srcSet: `${ASSET_URL}/Grace&Mila.png 1x, ${ASSET_URL}/Grace&Mila_2x.png 2x`,
    },
  },
  [Brand.NAF_NAF]: {
    name: 'NAFNAF',
    image: {
      src: `${ASSET_URL}/NAFNAF.png`,
      srcSet: `${ASSET_URL}/NAFNAF.png 1x, ${ASSET_URL}/NAFNAF_2x.png 2x`,
    },
  },
  [Brand.SCOTCH_SODA]: {
    name: 'SCOTCH&SODA',
    image: {
      src: `${ASSET_URL}/SCOTCH_SODA.png`,
      srcSet: `${ASSET_URL}/SCOTCH_SODA.png 1x, ${ASSET_URL}/SCOTCH_SODA_2x.png 2x`,
    },
  },
  [Brand.IKKS]: {
    name: 'IKKS',
    image: {
      src: `${ASSET_URL}/IKKS.png`,
      srcSet: `${ASSET_URL}/IKKS.png 1x, ${ASSET_URL}/IKKS_2x.png 2x`,
    },
  },
  [Brand.DIXIE]: {
    name: 'Dixie',
    image: {
      src: `${ASSET_URL}/Dixie.png`,
      srcSet: `${ASSET_URL}/Dixie.png 1x, ${ASSET_URL}/Dixie_2x.png 2x`,
    },
  },
  [Brand.JOULES]: {
    name: 'Joules',
    image: {
      src: `${ASSET_URL}/joules.png`,
      srcSet: `${ASSET_URL}/joules.png 1x, ${ASSET_URL}/joules_2x.png 2x`,
    },
  },
  [Brand.CARMAKOMA]: {
    name: 'Carmakoma',
    image: {
      src: `${ASSET_URL}/Carmakoma.png`,
      srcSet: `${ASSET_URL}/Carmakoma.png 1x, ${ASSET_URL}/Carmakoma_2x.png 2x`,
    },
  },
  [Brand.ESPRIT]: {
    name: 'Esprit',
    image: {
      src: `${ASSET_URL}/esprit.png`,
      srcSet: `${ASSET_URL}/esprit.png 1x, ${ASSET_URL}/esprit_2x.png 2x`,
    },
  },
  [Brand.MOSS_COPENHAGEN]: {
    name: 'Moss Copenhagen',
    image: {
      src: `${ASSET_URL}/Moss_Copenhagen.png`,
      srcSet: `${ASSET_URL}/Moss_Copenhagen.png 1x, ${ASSET_URL}/Moss_Copenhagen_2x.png 2x`,
    },
  },
  [Brand.TIFFOSI]: {
    name: 'Tiffosi',
    image: {
      src: `${ASSET_URL}/tiffosi.png`,
      srcSet: `${ASSET_URL}/tiffosi.png 1x, ${ASSET_URL}/tiffosi_2x.png 2x`,
    },
  },
  [Brand.LEE]: {
    name: 'Lee',
    image: {
      src: `${ASSET_URL}/lee.png`,
      srcSet: `${ASSET_URL}/lee.png 1x, ${ASSET_URL}/lee_2x.png 2x`,
    },
  },
  [Brand.FRENCH_CONNECTION]: {
    name: 'French Connection',
    image: {
      src: `${ASSET_URL}/frenchconnection.png`,
      srcSet: `${ASSET_URL}/frenchconnection.png 1x, ${ASSET_URL}/frenchconnection_2x.png 2x`,
    },
  },
  [Brand.PIECES]: {
    name: 'Pieces',
    image: {
      src: `${ASSET_URL}/pieces.png`,
      srcSet: `${ASSET_URL}/pieces.png 1x, ${ASSET_URL}/pieces_2x.png 2x`,
    },
  },
  [Brand.FATFACE]: {
    name: 'FATFACE',
    image: {
      src: `${ASSET_URL}/FF.png`,
      srcSet: `${ASSET_URL}/FF.png 1x, ${ASSET_URL}/FF_2x.png 2x`,
    },
  },
  [Brand.STREET_ONE]: {
    name: 'Street one',
    image: {
      src: `${ASSET_URL}/street_one.png`,
      srcSet: `${ASSET_URL}/street_one.png 1x, ${ASSET_URL}/street_one_2x.png 2x`,
    },
  },
  [Brand.SOFT_REBELS]: {
    name: 'Soft Rebels',
    image: {
      src: `${ASSET_URL}/SOFT_REBELS.png`,
      srcSet: `${ASSET_URL}/SOFT_REBELS.png 1x, ${ASSET_URL}/SOFT_REBELS_2x.png 2x`,
    },
  },
  [Brand.CKS]: {
    name: 'CKS',
    image: {
      src: `${ASSET_URL}/CKS.png`,
      srcSet: `${ASSET_URL}/CKS.png 1x, ${ASSET_URL}/CKS_2x.png 2x`,
    },
  },
  [Brand.GARCIA_JEANS]: {
    name: 'Garcia jeans',
    image: {
      src: `${ASSET_URL}/garcia.png`,
      srcSet: `${ASSET_URL}/garcia.png 1x, ${ASSET_URL}/garcia_2x.png 2x`,
    },
  },
  [Brand.B_YOUNG]: {
    name: 'Byoung',
    image: {
      src: `${ASSET_URL}/byoung.png`,
      srcSet: `${ASSET_URL}/byoung.png 1x, ${ASSET_URL}/byoung_2x.png 2x`,
    },
  },
  [Brand.FREEQUENT]: {
    name: 'Freequent',
    image: {
      src: `${ASSET_URL}/freequent.png`,
      srcSet: `${ASSET_URL}/freequent.png 1x, ${ASSET_URL}/freequent_2x.png 2x`,
    },
  },
  [Brand.JUNAROSE]: {
    name: 'JUNAROSE',
    image: {
      src: `${ASSET_URL}/Junarose.png`,
      srcSet: `${ASSET_URL}/Junarose.png 1x, ${ASSET_URL}/Junarose_2x.png 2x`,
    },
  },
  [Brand.SILVIAN_HEACH]: {
    name: 'Silvian Heach',
    image: {
      src: `${ASSET_URL}/Silvian_Heach.png`,
      srcSet: `${ASSET_URL}/Silvian_Heach.png 1x, ${ASSET_URL}/Silvian_Heach_2x.png 2x`,
    },
  },
  [Brand.SOAKEDINLUXURY]: {
    name: 'Soakedinluxury',
    image: {
      src: `${ASSET_URL}/soakedinluxury.png`,
      srcSet: `${ASSET_URL}/soakedinluxury.png 1x, ${ASSET_URL}/soakedinluxury_2x.png 2x`,
    },
  },
  [Brand.HOBBS]: {
    name: 'Hobbs',
    image: {
      src: `${ASSET_URL}/Hobbs.png`,
      srcSet: `${ASSET_URL}/Hobbs.png 1x, ${ASSET_URL}/Hobbs@2x.png 2x`,
    },
  },
  [Brand.WHISTLES]: {
    name: 'Whistles',
    image: {
      src: `${ASSET_URL}/Whistles.png`,
      srcSet: `${ASSET_URL}/Whistles.png 1x, ${ASSET_URL}/Whistles@2x.png 2x`,
    },
  },
  [Brand.REPLAY]: {
    name: 'Replay',
    image: {
      src: `${ASSET_URL}/Replay.png`,
      srcSet: `${ASSET_URL}/Replay.png 1x, ${ASSET_URL}/Replay@2x.png 2x`,
    },
  },
  [Brand.BROADWAY]: {
    name: 'Broadway',
    image: {
      src: `${ASSET_URL}/Broadway.png`,
      srcSet: `${ASSET_URL}/Broadway.png 1x, ${ASSET_URL}/Broadway@2x.png 2x`,
    },
  },
  [Brand.CECIL]: {
    name: 'Cecil',
    image: {
      src: `${ASSET_URL}/Cecil.png`,
      srcSet: `${ASSET_URL}/Cecil.png 1x, ${ASSET_URL}/Cecil@2x.png 2x`,
    },
  },
  [Brand.TOMTAILOR]: {
    name: 'Tomtailor',
    image: {
      src: `${ASSET_URL}/Tomtailor.png`,
      srcSet: `${ASSET_URL}/Tomtailor.png 1x, ${ASSET_URL}/Tomtailor@2x.png 2x`,
    },
  },
  [Brand.LEVIS]: {
    name: 'Levis',
    image: {
      src: `${ASSET_URL}/Levis.png`,
      srcSet: `${ASSET_URL}/Levis.png 1x, ${ASSET_URL}/Levis@2x.png 2x`,
    },
  },
  [Brand.TAMARIS]: {
    name: 'Tamaris',
    image: {
      src: `${ASSET_URL}/Tamaris.png`,
      srcSet: `${ASSET_URL}/Tamaris.png 1x, ${ASSET_URL}/Tamaris@2x.png 2x`,
    },
  },
  [Brand.SAMSOE_SAMSOE]: {
    name: 'Samsoe Samsoe',
    image: {
      src: `${ASSET_URL}/SamsoeSamsoe.png`,
      srcSet: `${ASSET_URL}/SamsoeSamsoe.png 1x, ${ASSET_URL}/SamsoeSamsoe@2x.png 2x`,
    },
  },
  [Brand.SELECTED_FEMME]: {
    name: 'Selected Femme',
    image: {
      src: `${ASSET_URL}/SelectedFemme.png`,
      srcSet: `${ASSET_URL}/SelectedFemme.png 1x, ${ASSET_URL}/SelectedFemme@2x.png 2x`,
    },
  },
  [Brand.MBYM]: {
    name: 'Mbym',
    image: {
      src: `${ASSET_URL}/mbym.png`,
      srcSet: `${ASSET_URL}/mbym.png 1x, ${ASSET_URL}/mbym@2x.png 2x`,
    },
  },
  [Brand.NUMPH]: {
    name: 'Numph',
    image: {
      src: `${ASSET_URL}/numph.png`,
      srcSet: `${ASSET_URL}/numph.png 1x, ${ASSET_URL}/numph@2x.png 2x`,
    },
  },
  [Brand.VAGABOND]: {
    name: 'Vagabond',
    image: {
      src: `${ASSET_URL}/Vagabond.png`,
      srcSet: `${ASSET_URL}/Vagabond.png 1x, ${ASSET_URL}/Vagabond@2x.png 2x`,
    },
  },
  [Brand.NAKD]: {
    name: 'NA-KD',
    image: {
      src: `${ASSET_URL}/Nakd.png`,
      srcSet: `${ASSET_URL}/Nakd.png 1x, ${ASSET_URL}/Nakd@2x.png 2x`,
    },
  },
  [Brand.LINDEX]: {
    name: 'Lindex',
    image: {
      src: `${ASSET_URL}/Lindex.png`,
      srcSet: `${ASSET_URL}/Lindex.png 1x, ${ASSET_URL}/Lindex@2x.png 2x`,
    },
  },
  [Brand.GERRY_WEBER]: {
    name: 'Gerry Weber',
    image: {
      src: `${ASSET_URL}/Gerry_Weber.png`,
      srcSet: `${ASSET_URL}/Gerry_Weber.png 1x, ${ASSET_URL}/Gerry_Weber@2x.png 2x`,
    },
  },
  [Brand.SOYACONCEPT]: {
    name: 'Soyaconcept',
    image: {
      src: `${ASSET_URL}/Soyaconcept.png`,
      srcSet: `${ASSET_URL}/Soyaconcept.png 1x, ${ASSET_URL}/Soyaconcept@2x.png 2x`,
    },
  },
  [Brand.SAINT_TROPEZ]: {
    name: 'Saint Tropez',
    image: {
      src: `${ASSET_URL}/Saint_Tropez.png`,
      srcSet: `${ASSET_URL}/Saint_Tropez.png 1x, ${ASSET_URL}/Saint_Tropez@2x.png 2x`,
    },
  },
};
