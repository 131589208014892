import {IOS_FB_HEADER_OFFSET, iOSInAppBrowser} from '@/utils/inAppBrowser';

interface Options {
  blockEnd: boolean;
}

export const scrollToElement = (id: string, {blockEnd = false}: Partial<Options> = {}): void => {
  const el = document.getElementById(id);

  if (!el) {
    return;
  }

  const {height: targetHeight, top: targetTop} = el.getBoundingClientRect();
  let delta = window.pageYOffset + targetTop;

  if (blockEnd) {
    delta = delta + (targetHeight - window.innerHeight);
  }

  if (!blockEnd && iOSInAppBrowser()) {
    delta = delta - IOS_FB_HEADER_OFFSET;
  }

  window.scrollTo({top: delta, behavior: 'smooth'});
};
