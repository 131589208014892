import React from 'react';
import {Section} from '@/components/atoms/Section';
import {Button, SPACE, Text, TitleIcon, useDevice, View} from '@lookiero/aurora';
import {useIntl} from '@/modules/I18N';
import {redirectToQuiz} from '@/modules/Quiz/redirectToQuiz';

import {useQuizAutoScroll} from '../hooks/useQuizAutoScroll';
import {QUIZ_ID} from '../services/scrollToQuiz';
import {QUIZ_END_SECTION_ID, TEXT} from './Q1Swap.definition';
import {QuizVideo} from './components/QuizVideo';
import {QuizOptions} from './components/QuizOptions';
import {styles} from './Q1Swap.styles';

const Q1Swap: React.FC = () => {
  const {screen} = useDevice();
  const {formatMessage} = useIntl();
  useQuizAutoScroll();

  const goToQuiz = () => {
    redirectToQuiz({skipFirstQuestion: false});
  };

  return (
    <>
      <Section nativeID={QUIZ_ID} layoutStyle={!screen.L && {paddingTop: 0}}>
        <View style={[styles.container, !screen.L && styles.containerS]}>
          <View style={styles.content}>
            <TitleIcon text={formatMessage(TEXT.HEADER)} icon={'circle_small'} />
            <Text as="h2" heading level={screen.L ? 2 : 3} marginTop={SPACE.SPACE_4}>
              {formatMessage(TEXT.TITLE)}
            </Text>
            <QuizOptions marginTop={SPACE.SPACE_8} />
            <Button onPress={goToQuiz} style={screen.L && styles.desktopCTA} marginTop={SPACE.SPACE_8}>
              {formatMessage(TEXT.CTA)}
            </Button>
          </View>
          <View style={[styles.media, screen.L ? styles.mediaDesktop : styles.mediaMobile]}>
            <QuizVideo style={screen.L ? styles.videoDesktop : undefined} />
          </View>
        </View>
      </Section>
      <div id={QUIZ_END_SECTION_ID} />
    </>
  );
};

const MemoQ1Swap = React.memo(Q1Swap);
export {MemoQ1Swap as Q1Swap};
