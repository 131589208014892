import {Site} from '@/types/Site';
import {BannerInformation} from '../types/BannerInformation';

export const COUNTRY_CONFIG: Record<Site, BannerInformation> = {
  [Site.BE]: {
    promo: true,
  },
  [Site.DE]: {
    promo: false,
  },
  [Site.ES]: {
    promo: true,
  },
  [Site.FR]: {
    promo: true,
  },
  [Site.IT]: {
    promo: false,
  },
  [Site.UK]: {
    promo: false,
  },
  [Site.PT]: {
    promo: false,
  },
  [Site.AT]: {
    promo: false,
  },
  [Site.NL]: {
    promo: false,
  },
  [Site.SE]: {
    promo: false,
  },
  [Site.CH]: {
    promo: false,
  },
};
