import StyleSheet from 'react-native-extended-stylesheet';

export const FIXED_CTA_HEIGHT = 72;

export const styles = StyleSheet.create({
  container: {
    display: 'flex',
    zIndex: '$elevationLayerS',
    position: 'fixed',
    backgroundColor: 'rgba(255, 255, 255, 0.80)',
    height: FIXED_CTA_HEIGHT,
    backdropFilter: 'blur(8px)',
    flexWrap: 'wrap',
    alignContent: 'center',
    paddingVertical: '$space3',
  },
  button: {
    width: 'auto',
  },
});
