import StyleSheet from 'react-native-extended-stylesheet';

export const styles = StyleSheet.create({
  layout: {
    alignSelf: 'center',
    justifyContent: 'center',
  },
  content: {
    maxWidth: 960,
  },
});
