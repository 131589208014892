import StyleSheet from 'react-native-extended-stylesheet';

export const styles = StyleSheet.create({
  linkContainer: {
    textAlign: 'left',
    marginTop: '$space6',
    color: '$colorTextLight',
  },
  link: {
    color: '$colorTextLight',
  },
});
