import React, {useEffect} from 'react';

import {PATH} from '@/routes';
import {FixedCTA, Intro, HowItWorks, PsIntro, AboutYourLookiero, AboutCost, Quiz, FaqSection} from './components';
import {Footer} from '@/components/atoms/Footer';
import {SEO} from '@/modules/SEO';
import {useIntersectionObserver} from '@/hooks';
import {setUserLandingCookie} from '@/utils/cookies/cookieUserLanding';
import {isUserLoggedIn} from '@/utils/isUserLoggedIn';
import {HomeContextProvider} from './context';

export const HomeView: React.FC<{isHeaderVisible: boolean}> = ({isHeaderVisible}) => {
  const {ref: introRef, inView: introInView} = useIntersectionObserver();
  const {ref: quizRef, inView: quizInView} = useIntersectionObserver();
  const {ref: quizTriggerRef, firstView: quizTrigger} = useIntersectionObserver({rootMargin: `0px 0px -50% 0px`});

  const isCTAVisible = !(introInView || quizInView);

  useEffect(() => {
    setUserLandingCookie();
  }, []);

  if (isUserLoggedIn()) {
    window.location.href = PATH.USER;
    return null;
  }

  return (
    <>
      <SEO>
        <link rel="alternate" hrefLang="x-default" href="https://lookiero.com" />
      </SEO>
      <HomeContextProvider>
        <FixedCTA isVisible={isCTAVisible} offset={isHeaderVisible} />
        <div ref={introRef}>
          <Intro />
        </div>
        <div ref={quizTriggerRef}>
          <HowItWorks />
          <PsIntro />
          <AboutYourLookiero />
          <AboutCost />
          <div ref={quizRef}>
            <Quiz renderQuiz={quizTrigger} />
          </div>
          <FaqSection />
          <Footer />
        </div>
      </HomeContextProvider>
    </>
  );
};
