import React, {FC, useEffect, useMemo} from 'react';

import {useIntl} from '@/modules/I18N';

import {sendLogMessage} from '@/modules/Monitoring';
import {ALIGN, InfoBox, INFOBOX_TYPE, INFOBOX_VARIANT} from '@lookiero/aurora';

export interface BannerPromoProps {
  id?: string;
  content: string;
}

export const BannerPromo: FC<BannerPromoProps> = ({id = 'banner', content}) => {
  const {formatMessage, loadedMessages} = useIntl();

  const message = useMemo(() => formatMessage({id: content}), [content, formatMessage]);

  useEffect(() => {
    if (loadedMessages && !message) {
      void sendLogMessage(`Promo localise key not found: "${content}"`);
    }
  }, [content, message, loadedMessages]);

  return (
    Boolean(message) && (
      <InfoBox
        variant={INFOBOX_VARIANT.PROMO}
        type={INFOBOX_TYPE.SQUARED}
        contentAlign={ALIGN.CENTER}
        nativeID={id}
        title={message}
      />
    )
  );
};
