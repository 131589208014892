import {Site} from '@/types/Site';

import {Brand} from '../types/Brand';

export const BRANDS_BY_SITE: Record<Site, Brand[]> = {
  [Site.FR]: [
    Brand.VILA,
    Brand.ONLY,
    Brand.ICHI,
    Brand.VERO_MODA,
    Brand.NAF_NAF,
    Brand.GRACE_MILA,
    Brand.IKKS,
    Brand.ESPRIT,
    Brand.LEE,
    Brand.STREET_ONE,
    Brand.SCOTCH_SODA,
    Brand.TIFFOSI,
    Brand.CARMAKOMA,
    Brand.PIECES,
    Brand.FREEQUENT,
  ],
  [Site.BE]: [
    Brand.VILA,
    Brand.ONLY,
    Brand.ICHI,
    Brand.VERO_MODA,
    Brand.NAF_NAF,
    Brand.GRACE_MILA,
    Brand.IKKS,
    Brand.ESPRIT,
    Brand.LEE,
    Brand.STREET_ONE,
    Brand.SCOTCH_SODA,
    Brand.TIFFOSI,
    Brand.CARMAKOMA,
    Brand.PIECES,
    Brand.FREEQUENT,
  ],
  [Site.IT]: [
    Brand.VILA,
    Brand.ONLY,
    Brand.ICHI,
    Brand.GRACE_MILA,
    Brand.NAF_NAF,
    Brand.SCOTCH_SODA,
    Brand.DIXIE,
    Brand.ESPRIT,
    Brand.LEE,
    Brand.SILVIAN_HEACH,
    Brand.SOAKEDINLUXURY,
    Brand.REPLAY,
    Brand.STREET_ONE,
    Brand.FRENCH_CONNECTION,
    Brand.PIECES,
  ],
  [Site.UK]: [
    Brand.VILA,
    Brand.ONLY,
    Brand.ICHI,
    Brand.VERO_MODA,
    Brand.HOBBS,
    Brand.WHISTLES,
    Brand.JOULES,
    Brand.MOSS_COPENHAGEN,
    Brand.FRENCH_CONNECTION,
    Brand.LEE,
    Brand.SOAKEDINLUXURY,
    Brand.FATFACE,
    Brand.ESPRIT,
    Brand.SOFT_REBELS,
    Brand.SCOTCH_SODA,
  ],
  [Site.ES]: [
    Brand.VILA,
    Brand.ONLY,
    Brand.ICHI,
    Brand.VERO_MODA,
    Brand.NAF_NAF,
    Brand.SCOTCH_SODA,
    Brand.CARMAKOMA,
    Brand.TIFFOSI,
    Brand.LEE,
    Brand.STREET_ONE,
    Brand.PIECES,
    Brand.GRACE_MILA,
    Brand.SOFT_REBELS,
    Brand.GARCIA_JEANS,
    Brand.ESPRIT,
  ],
  [Site.PT]: [
    Brand.VILA,
    Brand.ONLY,
    Brand.ICHI,
    Brand.VERO_MODA,
    Brand.NAF_NAF,
    Brand.GRACE_MILA,
    Brand.CARMAKOMA,
    Brand.TIFFOSI,
    Brand.MOSS_COPENHAGEN,
    Brand.STREET_ONE,
    Brand.ESPRIT,
    Brand.PIECES,
    Brand.CKS,
    Brand.B_YOUNG,
    Brand.JUNAROSE,
  ],
  [Site.DE]: [
    Brand.VILA,
    Brand.ONLY,
    Brand.ICHI,
    Brand.ESPRIT,
    Brand.VERO_MODA,
    Brand.TOMTAILOR,
    Brand.CARMAKOMA,
    Brand.STREET_ONE,
    Brand.LEE,
    Brand.BROADWAY,
    Brand.NAF_NAF,
    Brand.SCOTCH_SODA,
    Brand.LEVIS,
    Brand.CECIL,
    Brand.TAMARIS,
  ],
  [Site.AT]: [
    Brand.VILA,
    Brand.ONLY,
    Brand.ICHI,
    Brand.ESPRIT,
    Brand.VERO_MODA,
    Brand.TOMTAILOR,
    Brand.CARMAKOMA,
    Brand.STREET_ONE,
    Brand.LEE,
    Brand.BROADWAY,
    Brand.NAF_NAF,
    Brand.SCOTCH_SODA,
    Brand.LEVIS,
    Brand.CECIL,
    Brand.TAMARIS,
  ],
  [Site.NL]: [
    Brand.SAMSOE_SAMSOE,
    Brand.SCOTCH_SODA,
    Brand.VERO_MODA,
    Brand.LEVIS,
    Brand.FRENCH_CONNECTION,
    Brand.ESPRIT,
    Brand.SOFT_REBELS,
    Brand.SELECTED_FEMME,
    Brand.STREET_ONE,
    Brand.MBYM,
    Brand.TAMARIS,
    Brand.NUMPH,
    Brand.VILA,
    Brand.ICHI,
    Brand.ONLY,
  ],
  [Site.SE]: [
    Brand.SAMSOE_SAMSOE,
    Brand.VAGABOND,
    Brand.NAKD,
    Brand.LINDEX,
    Brand.ESPRIT,
    Brand.LEVIS,
    Brand.VERO_MODA,
    Brand.VILA,
    Brand.GERRY_WEBER,
    Brand.SOYACONCEPT,
    Brand.FRENCH_CONNECTION,
    Brand.SAINT_TROPEZ,
    Brand.SCOTCH_SODA,
    Brand.ONLY,
    Brand.TAMARIS,
  ],
  [Site.CH]: [
    Brand.VILA,
    Brand.ONLY,
    Brand.ICHI,
    Brand.ESPRIT,
    Brand.VERO_MODA,
    Brand.TOMTAILOR,
    Brand.CARMAKOMA,
    Brand.STREET_ONE,
    Brand.LEE,
    Brand.BROADWAY,
    Brand.NAF_NAF,
    Brand.SCOTCH_SODA,
    Brand.LEVIS,
    Brand.CECIL,
    Brand.TAMARIS,
  ],
};
