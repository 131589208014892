import React from 'react';

import styles from './LazyAnimation.module.css';

interface LazyAnimationProps {
  element: (node?: Element | null | undefined) => void;
  isVisible: boolean;
  children: React.ReactNode;
  containerStyle?: React.CSSProperties;
  elementStyle?: React.CSSProperties;
  disableAnimation?: boolean;
  animation?: 'simple' | 'slide';
}

const ANIMATION_STYLES = {
  simple: {in: styles.animationSimpleIn, out: styles.animationSimpleOut},
  slide: {in: styles.animationSlideIn, out: styles.animationSlideOut},
};

export const LazyAnimation: React.FC<LazyAnimationProps> = ({
  element,
  isVisible,
  containerStyle,
  elementStyle,
  children,
  disableAnimation = false,
  animation = 'simple',
}) => {
  const animationStyle = !disableAnimation && ANIMATION_STYLES[animation];
  const appendStyle = animationStyle ? (isVisible ? animationStyle.in : animationStyle.out) : '';

  return (
    <div ref={element} className={styles.container} style={containerStyle}>
      <div className={`${styles.element} ${appendStyle || ''}`} style={elementStyle}>
        {children}
      </div>
    </div>
  );
};
