import {useMendel} from '@/modules/ABTesting';
import {experiments} from '@/modules/ABTesting/mendel';

const EXPERIMENT = experiments.GRW_FAQS_HP;
export const useFaq = () => {
  const {isLoading, variant} = useMendel({
    name: EXPERIMENT.name,
  });

  return {
    isLoading: isLoading,
    enabled: variant === EXPERIMENT.variants.v1,
  };
};
