import React from 'react';
import {ALIGN, Button, FLEX_DIRECTION, Icon, Layout, SPACE, Text, useDevice, View} from '@lookiero/aurora';

import {useIntl} from '@/modules/I18N';
import {scrollToQuiz} from '@/pages/Home/services';
import {useMendelContext} from '@/modules/ABTesting';
import {styles} from './Intro.styles';
import {Banner, IntroMedia, TrustPilot} from './components';
import {IDS, TEXT} from './intro.definition';
import {getIntroConfig} from './services/getIntroConfig';

const {content, images} = getIntroConfig();

const Intro: React.FC = () => {
  const {screen} = useDevice();
  const {formatMessage} = useIntl();

  const {freeTrial} = useMendelContext();
  const expText = freeTrial.text?.cta;
  const CTA_TEXT = expText ? {id: expText} : content.cta;

  return (
    <View as="section" flexDirection={screen.L ? FLEX_DIRECTION.ROW : FLEX_DIRECTION.COLUMN} nativeID={IDS.SECTION}>
      <View as="section" style={[styles.content, screen.L && styles.contentDesktop]} nativeID="content-section">
        <Banner />
        <Layout
          fullWidth={screen.L}
          style={[styles.contentTop, screen.L && styles.contentTopDesktop]}
          marginBottom={images.trustpilot ? {S: 0, M: 0} : SPACE.SPACE_8}
          marginTop={SPACE.SPACE_8}
        >
          <Text
            as={screen.L ? 'h1' : 'h2'}
            heading
            level={screen.L ? 1 : 2}
            marginBottom={{L: SPACE.SPACE_6, default: SPACE.SPACE_4}}
            nativeID={IDS.TITLE}
          >
            {formatMessage(TEXT.TITLE)}
          </Text>
          <View style={[styles.contentBullets, screen.L && styles.contentBulletsDesktop]} nativeID={IDS.BULLETS}>
            {content.bullets.map(bullet => (
              <View style={styles.bullets} key={bullet.id}>
                <Icon name="tick_fill" style={styles.bulletIcon} />
                <Text level={screen.L ? 2 : 3} style={{alignSelf: ALIGN.CENTER}}>
                  {formatMessage(bullet)}
                </Text>
              </View>
            ))}
          </View>
          <Button nativeID={IDS.CTA} style={screen.L && styles.cta} onPress={scrollToQuiz}>
            {formatMessage(CTA_TEXT)}
          </Button>
          <TrustPilot />
        </Layout>
      </View>
      <View as="aside" nativeID={IDS.IMAGE} style={styles.media}>
        <IntroMedia />
      </View>
    </View>
  );
};

const MemoizedIntro = React.memo(Intro);
export {MemoizedIntro as Intro};
