import {getSiteConfig} from '@/modules/Site';
import {getUrlPromo} from '@/modules/Promotion';
import {SessionStorage} from '@/utils/sessionStorage';
import {COUNTRY_CONFIG} from '../config/bannerCountryConfig';
import {Banner, BannerInformation} from '../types';

const BANNER_PS_LOOKIERO = 'hidden_promo_code';

export const userHasClosedBannerSessionStorage = (): boolean => Boolean(SessionStorage.get(BANNER_PS_LOOKIERO));
export const closeBanner = () => SessionStorage.set(BANNER_PS_LOOKIERO, 'true');

interface Args {
  forcedFreeTrial?: boolean;
}

const bannerPsLookieroConfig = ({forcedFreeTrial}: Args): Banner | undefined => {
  const {site} = getSiteConfig();
  const {promo} = (site ? COUNTRY_CONFIG[site] : {}) as BannerInformation;

  const urlPromo = getUrlPromo();
  const hasFreeTrial = forcedFreeTrial !== undefined ? forcedFreeTrial : promo;

  if (urlPromo) {
    return {
      content: urlPromo,
      promoType: 'url',
    };
  } else if (hasFreeTrial) {
    return {
      content: 'banner.campaignWelcome.message',
      promoType: 'freeTrial',
    };
  }
  return undefined;
};

export const getBannerPromo = ({forcedFreeTrial}: Args = {}) =>
  !userHasClosedBannerSessionStorage() ? bannerPsLookieroConfig({forcedFreeTrial}) : undefined;
