import {IMAGES_URL} from '@/config';
import {Screen} from '@lookiero/aurora/build/hooks/useDevice/helpers/getScreen';
import {ImageSource} from '@/components/atoms/Image';

const ASSET_URL = `${IMAGES_URL}/additional-info`;

export const IDS = {
  SECTION: 'price',
  TITLE: 'price-title',
  SUB_TITLE: 'price-subtitle',
  BODY: 'price-body',
  IMAGE: 'price-image',
} as const;

export const TEXT = {
  TITLE: {
    defaultMessage: 'For every budget',
    id: 'home.additionalinfo2.header',
  },
  SUBTITLE: {
    defaultMessage: 'What does Lookiero cost?',
    id: 'home.additionalinfo2.subtitle',
  },
  QUOTE_1: {
    defaultMessage: 'Set your own price range. And order as often as you choose. No subscription needed.',
    id: 'home.additionalinfo2.quote1',
  },
  QUOTE_2: {
    defaultMessage: 'Your personal shopper styles you for £10. But we remove that fee when you keep any piece.',
    id: 'home.additionalinfo2.quote2',
  },
} as const;

export const getImages = (screen: Screen): ImageSource => ({
  avif: `${ASSET_URL}/v1/about_cost@x${screen.L ? 3 : screen.M ? 2 : 1}.avif`,
  webp: `${ASSET_URL}/v1/about_cost@x${screen.L ? 3 : screen.M ? 2 : 1}.webp`,
  default: `${ASSET_URL}/v1/about_cost@x${screen.L ? 3 : screen.M ? 2 : 1}.jpg`,
});
