import StyleSheet from 'react-native-extended-stylesheet';

export const PS_IMAGE_SIZE = 64;

export const styles = StyleSheet.create({
  imageHeader: {
    borderTopLeftRadius: '$borderRadius4',
    borderTopRightRadius: '$borderRadius4',
  },
  quote: {
    backgroundColor: '$colorBgBase',
    borderBottomLeftRadius: '$borderRadius4',
    borderBottomRightRadius: '$borderRadius4',
    padding: '$space4',
    paddingBottom: '$space6',
    flex: 1,
  },
  psImage: {
    width: PS_IMAGE_SIZE,
  },
  title: {
    flex: 1,
  },
});
