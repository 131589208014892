import React, {FC} from 'react';
import {ALIGN, Box, DISPLAY, FLEX_DIRECTION, SPACE, Text, useDevice, View} from '@lookiero/aurora';
import {Image} from '@/components/atoms';
import {useIntl} from '@/modules/I18N';
import {Step} from './types/Step';
import {styles} from './StepViewer.styles';

interface StepViewer {
  steps: Step[];
  onImageLoad?: () => void;
}

export const StepViewer: FC<StepViewer> = ({steps, onImageLoad}) => {
  const {screen} = useDevice();
  const {formatMessage} = useIntl();

  return (
    <View
      alignItems={ALIGN.LEFT}
      display={DISPLAY.FLEX}
      flexDirection={screen.L ? FLEX_DIRECTION.ROW : FLEX_DIRECTION.COLUMN}
      style={screen.L && styles.steps}
    >
      {steps.map(({title, summary, image}, index) => (
        <Box
          key={title.id}
          marginTop={{S: index !== 0 ? SPACE.SPACE_8 : null, M: index !== 0 ? SPACE.SPACE_12 : null}}
          display={DISPLAY.FLEX}
          style={styles.stepContainer}
        >
          <View marginBottom={SPACE.SPACE_6}>
            <Image
              height={360}
              width={288}
              lazyLoad
              onLoad={onImageLoad}
              src={image}
              animation="slide"
              imageStyle={styles.image}
            />
          </View>
          <Text as="h3" action level={2} marginBottom={SPACE.SPACE_1} nativeID={`step-${index + 1}-title`}>
            {formatMessage(title, {values: title.params})}
          </Text>
          <Text as="p" level={3} nativeID={`step-${index + 1}-summary`}>
            {formatMessage(summary, {values: summary.params})}
          </Text>
        </Box>
      ))}
    </View>
  );
};
