import React, {FC, useState} from 'react';
import {Accordion, COLOR, Text} from '@lookiero/aurora';
import {useIntl} from '@/modules/I18N';
import {TEXT} from './FaqSection.definition';
import {eventManager, UI_EVENT} from '@/modules/EventManager';
import {useMendelContext} from '@/modules/ABTesting';

const onAccordionOpen = (id: string) => eventManager.emit(UI_EVENT.FAQ_ACCORDION_EXPANDED, {id});

interface ContentProps {
  text: {id: string};
  title: {id: string};
  id: string;
}

const FaqSection: FC<ContentProps> = ({title, text, id}) => {
  const {formatMessage} = useIntl();
  const [active, setActive] = useState<boolean>(false);

  return (
    <Accordion
      title={formatMessage(title)}
      onPress={() => {
        setActive(!active);
        if (!active) {
          onAccordionOpen(id);
        }
      }}
      active={active}
      nativeID={id}
    >
      <Text detail level={1} color={COLOR.TEXT_MEDIUM}>
        {formatMessage(text)}
      </Text>
    </Accordion>
  );
};

export const FaqAccordion: FC = () => {
  const {freeTrial} = useMendelContext();
  return (
    <>
      <FaqSection title={TEXT.PS_TITLE} text={TEXT.PS_TEXT} id={'ps'} />
      <FaqSection
        title={TEXT.PRICE_TITLE}
        text={freeTrial.hasFreeTrial ? TEXT.PRICE_TEXT_FREE : TEXT.PRICE_TEXT_NOT_FREE}
        id={'price'}
      />
      <FaqSection title={TEXT.SIZES_TITLE} text={TEXT.SIZES_TEXT} id={'sizes'} />
      <FaqSection title={TEXT.PREVIEW_TITLE} text={TEXT.PREVIEW_TEXT} id={'preview'} />
      <FaqSection title={TEXT.TIME_TITLE} text={TEXT.TIME_TEXT} id={'time'} />
      <FaqSection title={TEXT.SHIPPING_TITLE} text={TEXT.SHIPPING_TEXT} id={'shipping'} />
    </>
  );
};
