import React from 'react';
import StyleSheet from 'react-native-extended-stylesheet';
import {Touchable, Text} from '@lookiero/aurora';

interface PillButtonProps {
  id: string;
  value: string;
  checked?: boolean;
  onPress: (id: string) => void;
}

const styles = StyleSheet.create({
  pill: {
    borderRadius: 9999,
    paddingVertical: '$space2',
    paddingHorizontal: '$space4',
  },
  checked: {
    backgroundColor: '$colorBgInteractiveSelected',
  },
  unchecked: {
    backgroundColor: '$colorBgInteractiveActive',
  },
  text: {
    lineHeight: '$space5',
  },
});

export const PillButton: React.FC<PillButtonProps> = ({id, value, onPress, checked = false}) => {
  const checkStyle = checked ? styles.checked : styles.unchecked;

  return (
    <Touchable style={[styles.pill, checkStyle]} onPress={() => onPress(id)}>
      <Text detail level={1} style={styles.text}>
        {value}
      </Text>
    </Touchable>
  );
};
