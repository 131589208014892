import React, {FC, useMemo} from 'react';
import {useScrollLock} from '@/hooks/useScrollLock';
import {Image, Modal, ScrollView, useDevice} from '@lookiero/aurora';
import {useIntl} from '@/modules/I18N';
import {IN_APP_BROWSER_MODAL_PADDING_BOTTOM, iOSInAppBrowser, isAndroidInAppBrowser} from '@/utils/inAppBrowser';

import {getBrandsFromSite} from '../../services/getBrandsFromSite';
import {IDS, TEXT, BRANDS_DEF} from './BrandsModal.definition';
import {styles} from './BrandsModal.styles';

interface BrandsModalProps {
  isVisible: boolean;
  onClose: () => void;
}

export const BrandsModal: FC<BrandsModalProps> = ({isVisible, onClose}) => {
  const {screen} = useDevice();
  const {formatMessage} = useIntl();
  useScrollLock(isVisible);

  const brands = useMemo(() => getBrandsFromSite().map(brand => BRANDS_DEF[brand]), []);

  const modalStyles = !screen.L ? (isVisible ? styles.modalMobileVisible : styles.modalMobileNotVisible) : null;

  /* Workaround to fix unexpected padding bottom behavior with modals in FB video Ads in-app browser */
  const isFBInAppBrowser = iOSInAppBrowser() || isAndroidInAppBrowser();
  const modalPaddingBottom = isFBInAppBrowser ? {paddingBottom: IN_APP_BROWSER_MODAL_PADDING_BOTTOM} : {};
  /* ***** */

  return (
    <Modal
      nativeID={IDS.MODAL_ID}
      title={formatMessage(TEXT.MODAL_TITLE)}
      description={formatMessage(TEXT.MODAL_DESCRIPTION)}
      isVisible={isVisible}
      onClose={onClose}
      style={modalStyles}
      contentStyle={modalPaddingBottom}
    >
      <ScrollView contentContainerStyle={styles.content}>
        {brands.map(brand => (
          <Image style={styles.img} alt={brand.name} key={brand.name} {...brand.image} />
        ))}
      </ScrollView>
    </Modal>
  );
};
