import StyleSheet from 'react-native-extended-stylesheet';

import {HEADER_HEIGHT} from '@/components/organisms/Header';

export const styles = StyleSheet.create({
  content: {
    flex: 1,
    alignSelf: 'center',
  },
  contentDesktop: {
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
  },
  media: {
    flex: 1,
  },
  contentTop: {
    justifyContent: 'center',
    flexDirection: 'column',
  },
  contentTopDesktop: {
    maxWidth: 464,
    flex: 1,
  },
  contentBullets: {
    marginBottom: '$space8',
    flexDirection: 'column',
    gap: '$space2',
  },
  contentBulletsDesktop: {
    gap: '$space4',
  },
  bullets: {
    textAlign: 'left',
    flexDirection: 'row',
  },
  bulletIcon: {
    marginRight: '$space4',
  },
  cta: {
    maxWidth: 298,
  },
});
