import React, {useState, useCallback} from 'react';

import {isIOS} from '@/utils/isIOS';
import {useIntersectionObserver} from '@/hooks';
import {LazyAnimation} from '@/components/atoms/LazyAnimation';

import styles from './Image.module.css';

export interface ImageSrc {
  avif?: string;
  webp?: string;
  default: string;
}

export interface ImageProps {
  alt?: string;
  animation?: 'slide' | 'simple';
  height: number;
  onLoad?: () => void;
  src: ImageSrc;
  width: number;
  lazyLoad?: boolean;
  fullHeight?: boolean;
  imageStyle?: React.CSSProperties;
}

export const Image: React.FC<ImageProps> = ({
  alt = '',
  height,
  onLoad,
  src,
  width,
  lazyLoad = false,
  fullHeight = false,
  imageStyle,
  animation,
}) => {
  const [resourceReady, setResourceReady] = useState(false);

  const {ref, firstView} = useIntersectionObserver();

  const paddingTop = `${(height / width) * 100}%`;
  const renderElement = !lazyLoad || (lazyLoad && firstView);

  const handleResourceReady = useCallback(() => {
    setResourceReady(true);
    if (onLoad) onLoad();
  }, [onLoad]);

  return (
    <LazyAnimation
      element={ref}
      disableAnimation={!lazyLoad}
      animation={animation}
      isVisible={renderElement && resourceReady}
      elementStyle={fullHeight ? {height: '100%'} : {paddingTop}}
    >
      {renderElement && (
        <picture>
          {!isIOS && src.avif && <source type="image/avif" srcSet={src.avif} />}
          {src.webp && <source type="image/webp" srcSet={src.webp} />}
          <img
            src={src.default}
            onLoad={handleResourceReady}
            alt={alt}
            className={styles.image}
            style={imageStyle}
            {...{height, width}}
          />
        </picture>
      )}
    </LazyAnimation>
  );
};
