import StyleSheet from 'react-native-extended-stylesheet';

export const IMAGE_SIZE = 64;

export const styles = StyleSheet.create({
  container: {
    backgroundColor: '$colorBgBase',
    borderRadius: '$borderRadius5',
    padding: '$space6',
    height: '100%',
  },
  image: {
    width: IMAGE_SIZE,
  },
  title: {
    flex: 1,
  },
});
