import {FIXED_CTA_HEIGHT} from '../FixedCTA.styles';
import {HEADER_HEIGHT} from '@/components/organisms/Header';

const getTopPosition = ({isVisible, offset}: {isVisible: boolean; offset: boolean}): number => {
  if (isVisible && offset) {
    return HEADER_HEIGHT;
  } else if (isVisible && !offset) {
    return 0;
  }
  return FIXED_CTA_HEIGHT * -1;
};

const getBottomPosition = ({isVisible}: {isVisible: boolean}) => {
  if (isVisible) {
    return 0;
  }
  return FIXED_CTA_HEIGHT * -1;
};

export {getBottomPosition, getTopPosition};
