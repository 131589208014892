import React, {useState} from 'react';

import {IntroImage} from './IntroImage';
import {IntroVideo} from './IntroVideo';

export const IntroMedia: React.FC = () => {
  const [videoError, setVideoError] = useState(false);

  if (!videoError) {
    return <IntroVideo onError={() => setVideoError(true)} />;
  }

  return <IntroImage />;
};
