import React from 'react';
import StyleSheet from 'react-native-extended-stylesheet';
import {useDevice} from '@lookiero/aurora';

import {Video} from '@/components/atoms/Video';
import {HEADER_HEIGHT} from '@/components/organisms/Header';
import {useMendelContext} from '@/modules/ABTesting';

const styles = StyleSheet.create({
  video: {
    objectPosition: 'top',
  },
  videoDesktop: {
    maxHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
  },
});

interface IntroVideoProps {
  onError?: () => void;
}

export const IntroVideo: React.FC<IntroVideoProps> = ({onError}) => {
  const {screen} = useDevice();
  const {
    talents: {video},
  } = useMendelContext();

  const videoStyle = screen.L ? styles.videoDesktop : {};

  return (
    <Video
      src={video}
      style={{...styles.video, ...videoStyle}}
      onError={onError}
      fullHeight={screen.L}
      width={640}
      height={800}
    />
  );
};
