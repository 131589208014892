import StyleSheet from 'react-native-extended-stylesheet';

export const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    gap: '$space12',
  },
  containerS: {
    flexDirection: 'column',
    gap: '$space8',
  },
  content: {
    flex: 1,
  },
  imageContainer: {
    flex: 1,
    width: '100%',
  },
  image: {
    borderRadius: '$borderRadius4',
  },
  title: {
    textAlign: 'left',
    color: '$colorTextLight',
  },
  description: {
    textAlign: 'left',
    color: '$colorTextLight',
  },
});
