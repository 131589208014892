import React from 'react';
import {SPACE, Text, useDevice, View, TitleIcon, Theme, COLOR} from '@lookiero/aurora';
import {useIntl} from '@/modules/I18N';
import {Section} from '@/components/atoms/Section';

import {IDS, TEXT, VIDEO} from './PainSlider.definition';
import {styles} from './PainSlider.styles';
import {ScrollView} from './ScrollView';
import {QuoteVideo} from './QuoteVideo';

const {space6, space10} = Theme.get();

const PainSlider: React.FC = () => {
  const {screen} = useDevice();
  const {formatMessage} = useIntl();

  const isDesktop = screen.L;
  const Scroll = isDesktop ? React.Fragment : ScrollView;

  return (
    <Section nativeID={IDS.SECTION} backgroundColor={COLOR.BG_PRIMARY_LIGHT}>
      <TitleIcon text={formatMessage(TEXT.HEADER)} icon="double_semicircle" />

      <Text heading level={isDesktop ? 2 : 3} marginTop={{L: SPACE.SPACE_2, default: SPACE.SPACE_4}}>
        {formatMessage(TEXT.TITLE)}
      </Text>

      <View marginTop={{L: SPACE.SPACE_12, default: SPACE.SPACE_8}}>
        <Scroll>
          <View style={styles.container}>
            <QuoteVideo
              src={VIDEO.BULLET1_VIDEO}
              style={styles.quote}
              marginRight={{L: SPACE.SPACE_6, default: SPACE.SPACE_3}}
              title={formatMessage(TEXT.BULLET1_TITLE)}
              description={formatMessage(TEXT.BULLET1_TEXT)}
            />
            <QuoteVideo
              src={VIDEO.BULLET2_VIDEO}
              style={styles.quote}
              marginRight={{L: SPACE.SPACE_6, default: SPACE.SPACE_3}}
              title={formatMessage(TEXT.BULLET2_TITLE)}
              description={formatMessage(TEXT.BULLET2_TEXT)}
            />
            <QuoteVideo
              src={VIDEO.BULLET3_VIDEO}
              style={styles.quote}
              title={formatMessage(TEXT.BULLET3_TITLE)}
              description={formatMessage(TEXT.BULLET3_TEXT)}
            />
            {/* fake spacing to allow a padding right inside the scroll */}
            {!isDesktop && <View style={{width: screen.M ? space10 : space6}}></View>}
          </View>
        </Scroll>
      </View>
    </Section>
  );
};

const MemoizedPainSlider = React.memo(PainSlider);
export {MemoizedPainSlider as PainSlider};
