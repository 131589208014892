import StyleSheet from 'react-native-extended-stylesheet';

export const styles = StyleSheet.create({
  video: {
    borderTopLeftRadius: '$borderRadius4',
    borderTopRightRadius: '$borderRadius4',
  },
  quote: {
    backgroundColor: '$colorBgBase',
    borderBottomLeftRadius: '$borderRadius4',
    borderBottomRightRadius: '$borderRadius4',
    padding: '$space4',
    paddingBottom: '$space6',
    flex: 1,
  },
});
