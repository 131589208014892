import {ASSETS_URL} from '@/config';

export const IDS = {
  SECTION: 'inside',
} as const;

export const TEXT = {
  HEADER: {id: 'home.pains.header'},
  TITLE: {id: 'home.pains.title'},
  BULLET1_TITLE: {id: 'home.pains.pain1title'},
  BULLET1_TEXT: {id: 'home.pains.pain1body'},
  BULLET2_TITLE: {id: 'home.pains.pain2title'},
  BULLET2_TEXT: {id: 'home.pains.pain2body'},
  BULLET3_TITLE: {id: 'home.pains.pain3title'},
  BULLET3_TEXT: {id: 'home.pains.pain3body'},
} as const;

export const VIDEO = {
  BULLET1_VIDEO: `${ASSETS_URL}/videos/pains-slider/PainsSlider1.mp4`,
  BULLET2_VIDEO: `${ASSETS_URL}/videos/pains-slider/PainsSlider2.mp4`,
  BULLET3_VIDEO: `${ASSETS_URL}/videos/pains-slider/PainsSlider3.mp4`,
} as const;
