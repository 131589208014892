import StyleSheet from 'react-native-extended-stylesheet';

export const styles = StyleSheet.create({
  trustpilotImage: {
    height: 50,
    minWidth: 98,
    width: 98,
  },
  trustpilotWrapper: {
    width: 250,
    flexDirection: 'row',
  },
});
