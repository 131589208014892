import React, {FC} from 'react';
import {ALIGN, FLEX_DIRECTION, SPACE, Text, View, ViewProperties} from '@lookiero/aurora';

import {Image} from '@/components/atoms';
import {styles, IMAGE_SIZE} from './Quote.styles';

interface QuoteProps extends ViewProperties {
  personName: string;
  personRole: string;
  message: string;
  imageUrl: string;
}

export const Quote: FC<QuoteProps> = ({message, personName, personRole, imageUrl, ...rest}) => {
  return (
    <View {...rest}>
      <View style={styles.container}>
        <View flexDirection={FLEX_DIRECTION.ROW} alignItems={ALIGN.CENTER}>
          <View style={styles.image} marginRight={SPACE.SPACE_4}>
            <Image height={IMAGE_SIZE} width={IMAGE_SIZE} src={{default: imageUrl}} lazyLoad />
          </View>
          <View style={styles.title}>
            <Text action level={2}>
              {personName}
            </Text>
            <Text detail level={1}>
              {personRole}
            </Text>
          </View>
        </View>
        <Text level={3} marginTop={SPACE.SPACE_6}>
          {message}
        </Text>
      </View>
    </View>
  );
};
