import {useMemo} from 'react';
import {useDevice} from '@lookiero/aurora';

import {useMendelContext} from '@/modules/ABTesting';
import {getSteps} from '../services/howItWorkService';
import type {Step} from '@/components/atoms';

export const useSteps = (): Step[] => {
  const {screen} = useDevice();

  const {freeTrial, talents} = useMendelContext();
  const expText = freeTrial.text?.howItWorks;

  return useMemo(() => {
    const variation = talents.isExpEnabled ? 'grw_Jun24Talents' : undefined;
    const stepsDefinition = variation ? getSteps(screen.L, 'rebrand', variation) : getSteps(screen.L);
    const [budget, ...rest] = stepsDefinition;
    const newBudget = budget ? [{...budget, summary: expText ? {id: expText} : budget.summary}] : [];
    return [...newBudget, ...rest];
  }, [screen.L, expText, talents.isExpEnabled]);
};
