import React, {useMemo, useCallback} from 'react';
import StyleSheet from 'react-native-extended-stylesheet';
import {View, ViewProperties} from '@lookiero/aurora';
import {useIntl} from '@/modules/I18N';
import {scrollToElement} from '@/utils/scrollToElement';
import {eventManager, UI_EVENT} from '@/modules/EventManager';

import {PillButton} from './PillButton';
import {QUIZ_END_SECTION_ID} from '../Q1Swap.definition';
import {useCheckList} from '../hooks/useCheckList';

export const OPTIONS: {id: string; translationKey: string}[] = [
  {id: 'home_motivational_opt1', translationKey: 'exp_quiz1out_home.quiz.opt1'},
  {id: 'home_motivational_opt2', translationKey: 'exp_quiz1out_home.quiz.opt2'},
  {id: 'home_motivational_opt3', translationKey: 'exp_quiz1out_home.quiz.opt3'},
  {id: 'home_motivational_opt4', translationKey: 'exp_quiz1out_home.quiz.opt4'},
  {id: 'home_motivational_opt5', translationKey: 'exp_quiz1out_home.quiz.opt5'},
  {id: 'home_motivational_opt6', translationKey: 'exp_quiz1out_home.quiz.opt6'},
];

const styles = StyleSheet.create({
  container: {
    gap: '$space3',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
});

export const QuizOptions: React.FC<ViewProperties> = ({...rest}) => {
  const {formatMessage} = useIntl();

  const {toggle, isChecked} = useCheckList();

  const options: {id: string; value: string}[] = useMemo(
    () => OPTIONS.map(({id, translationKey}) => ({id, value: formatMessage({id: translationKey})})),
    [formatMessage],
  );

  const onPress = useCallback(
    (id: string) => {
      const checked = toggle(id);
      if (checked) eventManager.emit(UI_EVENT.Q1_OPTION_SELECT, {event: id});
      scrollToElement(QUIZ_END_SECTION_ID, {blockEnd: true});
    },
    [toggle],
  );

  return (
    <View style={styles.container} {...rest}>
      {options.map(({id, value}) => (
        <PillButton key={id} id={id} value={value} onPress={onPress} checked={isChecked(id)} />
      ))}
    </View>
  );
};
