import StyleSheet from 'react-native-extended-stylesheet';

export const styles = StyleSheet.create({
  icon: {
    width: 48,
    height: 48,
    borderColor: '$colorBgWarning',
    borderRadius: '100%',
    backgroundColor: '$colorBgWarning',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
