import {IMAGES_URL} from '@/config';

export const TEXT = {
  QUOTE1_MESSAGE: {id: 'exp_home.stylecurators.quote1'},
  QUOTE1_PS: {id: 'home.stylecurators.ps_1'},
  QUOTE2_MESSAGE: {id: 'exp_home.stylecurators.quote2'},
  QUOTE2_PS: {id: 'home.stylecurators.ps_3'},
  QUOTE3_MESSAGE: {id: 'exp_home.stylecurators.quote3'},
  QUOTE3_PS: {id: 'home.stylecurators.ps_2'},
  QUOTE_ROL: {id: 'home.stylecurators.ps_rol'},
} as const;

const IMAGES_PATH = `${IMAGES_URL}/personal-shopper-intro/rebrand/ps`;

export const PS_IMAGES = {
  IMAGE_1: `${IMAGES_PATH}/03@2x.png`,
  IMAGE_2: `${IMAGES_PATH}/02@2x.png`,
  IMAGE_3: `${IMAGES_PATH}/01@2x.png`,
} as const;
