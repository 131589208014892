import React from 'react';
import {Button, Motion, useDevice} from '@lookiero/aurora';

import {useIntl} from '@/modules/I18N';
import {scrollToQuiz} from '@/pages/Home/services';
import {TEXT, IDS} from './FixedCTA.definition';
import {getBottomPosition, getTopPosition} from './services/styleService';
import {styles} from './FixedCTA.styles';
import {useMendelContext} from '@/modules/ABTesting';

interface FixedCTAProps {
  isVisible: boolean;
  offset: boolean;
}

export const FixedCTA: React.FC<FixedCTAProps> = ({isVisible = false, offset}) => {
  const {formatMessage} = useIntl();
  const {screen} = useDevice();

  const {freeTrial} = useMendelContext();
  const expText = freeTrial.text?.cta;
  const CTA_TEXT = expText ? {id: expText} : TEXT.CTA;

  return (
    <Motion
      style={styles.container}
      value={{
        top: screen.L ? getTopPosition({isVisible, offset}) : 'initial',
        bottom: !screen.L ? getBottomPosition({isVisible}) : 'auto',
      }}
      wide
    >
      <Button style={styles.button} onPress={scrollToQuiz} small nativeID={IDS.CTA}>
        {formatMessage(CTA_TEXT)}
      </Button>
    </Motion>
  );
};
