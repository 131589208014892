import {useMemo} from 'react';
import {useMendel} from '@/modules/ABTesting';
import {experiments} from '@/modules/ABTesting/mendel/config/Experiments.definition';

const {GRW_PAIN_SLIDER} = experiments;

export interface PainSliderResult {
  isLoading: boolean;
  variant: 'v1' | undefined;
}

export const usePainSlider = (): PainSliderResult => {
  const {isLoading, variant} = useMendel({name: GRW_PAIN_SLIDER.name});

  const result = useMemo(() => {
    if (variant === GRW_PAIN_SLIDER.variants.v1) return 'v1';
    return undefined;
  }, [variant]);

  return useMemo(() => ({isLoading, variant: result}), [isLoading, result]);
};
