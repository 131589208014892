import StyleSheet from 'react-native-extended-stylesheet';

export const styles = StyleSheet.create({
  quotesContainer: {
    display: 'flex',
    gap: '$space6',
  },
  quotesContainerL: {
    flexDirection: 'row',
    marginTop: '$space12',
  },
  quotesContainerS: {
    flexDirection: 'column',
    marginTop: '$space8',
  },
  quote: {
    flex: 1,
  },
});
