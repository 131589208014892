import React from 'react';
import {SPACE, Theme, useDevice, View} from '@lookiero/aurora';
import {useIntl} from '@/modules/I18N';
import {QuoteWithImage} from './QuoteWithImage';
import {ScrollView} from './ScrollView';
import {styles} from './ProductShowcase.styles';
import {PS_IMAGES, TEXT} from './ProductShowcase.definition';
import {IMAGES_URL} from '@/config';
import {ImageSrc} from '@/components/atoms/Image/Image';

const IMAGES_PATH = `${IMAGES_URL}/personal-shopper-intro/style-curators`;

const getImageSrc = (file: string): ImageSrc => ({
  default: `${IMAGES_PATH}${file}.jpg`,
  avif: `${IMAGES_PATH}${file}.avif`,
  webp: `${IMAGES_PATH}${file}.webp`,
});

const IMAGES_V1 = {
  IMAGE_1: getImageSrc('/Model/Model-3@x2'),
  IMAGE_2: getImageSrc('/Model/Model-2@x2'),
  IMAGE_3: getImageSrc('/Model/Model-1@x2'),
} as const;

const IMAGES_V2 = {
  IMAGE_1: getImageSrc('/Box/Box-1@x2'),
  IMAGE_2: getImageSrc('/Box/Box-2@x2'),
  IMAGE_3: getImageSrc('/Box/Box-3@x2'),
} as const;

const {space6, space10} = Theme.get();

const ProductShowcase: React.FC<{variant: 'v1' | 'v2'}> = ({variant}) => {
  const {screen} = useDevice();
  const {formatMessage} = useIntl();

  const isDesktop = screen.L;
  const headerImages = variant === 'v1' ? IMAGES_V1 : IMAGES_V2;
  const Scroll = isDesktop ? React.Fragment : ScrollView;

  return (
    <View marginTop={{L: SPACE.SPACE_12, default: SPACE.SPACE_8}}>
      <Scroll>
        <View style={styles.container}>
          <QuoteWithImage
            style={styles.quote}
            marginRight={{L: SPACE.SPACE_6, default: SPACE.SPACE_3}}
            headerImage={headerImages.IMAGE_1}
            psMessage={formatMessage(TEXT.QUOTE1_MESSAGE)}
            psName={formatMessage(TEXT.QUOTE1_PS)}
            psRole={formatMessage(TEXT.QUOTE_ROL)}
            psImage={PS_IMAGES.IMAGE_1}
          />
          <QuoteWithImage
            style={styles.quote}
            marginRight={{L: SPACE.SPACE_6, default: SPACE.SPACE_3}}
            headerImage={headerImages.IMAGE_2}
            psMessage={formatMessage(TEXT.QUOTE2_MESSAGE)}
            psName={formatMessage(TEXT.QUOTE2_PS)}
            psRole={formatMessage(TEXT.QUOTE_ROL)}
            psImage={PS_IMAGES.IMAGE_2}
          />
          <QuoteWithImage
            style={styles.quote}
            headerImage={headerImages.IMAGE_3}
            psMessage={formatMessage(TEXT.QUOTE3_MESSAGE)}
            psName={formatMessage(TEXT.QUOTE3_PS)}
            psRole={formatMessage(TEXT.QUOTE_ROL)}
            psImage={PS_IMAGES.IMAGE_3}
          />
          {/* fake spacing to allow a padding right inside the scroll */}
          {!isDesktop && <View style={{width: screen.M ? space10 : space6}}></View>}
        </View>
      </Scroll>
    </View>
  );
};

export {ProductShowcase};
