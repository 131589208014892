import React from 'react';
import {StyleCuratorContent as Control} from './control';
import {ProductShowcase} from './ProductShowcase';
import {useHomeContext} from '@/pages/Home/context';

const Factory: React.FC = () => {
  const {productShowcase} = useHomeContext();

  if (productShowcase.variant === 'v1') return <ProductShowcase variant="v1" />;
  if (productShowcase.variant === 'v2') return <ProductShowcase variant="v2" />;

  return <Control />;
};

export {Factory};
