import React, {useContext, useMemo, useCallback, useState, useEffect} from 'react';

import {useLocation} from '@/routes';
import {goToHomeSection} from '../services';
import {ProductShowcaseResult, useProductShowcase} from '../hooks/useProductShowcase';
import {PainSliderResult, usePainSlider} from '../hooks/usePainSlider';

interface HomeContextValue {
  onLoadContent: () => void;
  productShowcase: ProductShowcaseResult;
  painSlider: PainSliderResult;
}

const initialValue: HomeContextValue = {
  onLoadContent: () => {},
  productShowcase: {isLoading: false, variant: undefined},
  painSlider: {isLoading: false, variant: undefined},
};

const HomeContext = React.createContext<HomeContextValue>(initialValue);

const HomeContextProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [contentLoaded, setContentLoaded] = useState(false);
  const productShowcase = useProductShowcase();
  const painSlider = usePainSlider();

  const {hash} = useLocation();

  useEffect(() => {
    if (contentLoaded) goToHomeSection(hash);
  }, [contentLoaded, hash]);

  const onLoadContent = useCallback(() => {
    setContentLoaded(true);
  }, []);

  const value = useMemo(() => ({onLoadContent, productShowcase, painSlider}), [
    onLoadContent,
    productShowcase,
    painSlider,
  ]);

  return <HomeContext.Provider value={value}>{children}</HomeContext.Provider>;
};

const useHomeContext = () => {
  const context = useContext(HomeContext);

  if (context === undefined) {
    throw new Error('useHomeContext must be used within HomeContextProvider');
  }

  return context;
};

export {useHomeContext, HomeContextProvider};
