import React, {useCallback, useState} from 'react';
import {Link, Portal, Text} from '@lookiero/aurora';
import {useIntl} from '@/modules/I18N';

import {IDS} from '../../PSIntro.definition';
import {BrandsModal} from '../BrandsModal';
import {TEXT} from './StyleCurator.definition';
import {styles} from './BrandsLink.styles';

const ModalLink: React.FC<{onPress: () => void; children: React.ReactNode}> = ({onPress, children}) => {
  return (
    <Link nativeID={IDS.BODY_LINK} level={1} detail underlined style={styles.link} onPress={onPress}>
      {children}
    </Link>
  );
};

export const BrandsLink: React.FC = () => {
  const {formatMessage} = useIntl();

  const [isModalVisible, setModalVisible] = useState(false);
  const [renderModal, setRenderModal] = useState(false);

  const handleClick = useCallback(() => {
    const isRendered = renderModal;
    setRenderModal(true);
    setTimeout(() => setModalVisible(true), !isRendered ? 200 : 0);
  }, [renderModal]);

  return (
    <>
      <Text as="p" level={1} detail style={styles.linkContainer}>
        {formatMessage(TEXT.LINK_TEXT, {
          values: {
            link: <ModalLink onPress={handleClick}>{formatMessage(TEXT.LINK)}</ModalLink>,
          },
        })}
      </Text>
      {renderModal && (
        <Portal id="modal-brands">
          <BrandsModal isVisible={isModalVisible} onClose={() => setModalVisible(false)} />
        </Portal>
      )}
    </>
  );
};
