import React, {useMemo} from 'react';

import {useMendelContext} from '@/modules/ABTesting';
import {isSeoRender} from '@/modules/SEO';
import {BannerPromo} from './BannerPromo';
import {IDS} from './Banner.definition';
import {getBannerPromo} from '../services/bannerPromoService';

export const Banner: React.FC = () => {
  const {freeTrial} = useMendelContext();
  const {isLoading, isExpEnabled, hasFreeTrial: expFreeTrial} = freeTrial;

  const banner = useMemo(() => {
    return getBannerPromo({forcedFreeTrial: isExpEnabled ? expFreeTrial : undefined});
  }, [isExpEnabled, expFreeTrial]);

  const renderBanner = !isSeoRender && !isLoading && banner;

  return <>{renderBanner && <BannerPromo content={banner.content} id={IDS.BANNER} />}</>;
};
