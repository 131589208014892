import StyleSheet from 'react-native-extended-stylesheet';

export const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    gap: '$space12',
  },
  containerS: {
    flexDirection: 'column-reverse',
    gap: '$space8',
  },
  content: {
    flex: 1,
  },
  media: {
    flex: 1,
  },
  mediaDesktop: {
    width: '100%',
  },
  mediaMobile: {
    width: '100vw',
  },
  videoDesktop: {
    borderRadius: '$borderRadius4',
  },
  desktopCTA: {
    maxWidth: 327,
  },
});
