import React from 'react';
import {COLOR, SPACE, Text, TitleIcon, useDevice, View} from '@lookiero/aurora';

import {useIntl} from '@/modules/I18N';
import {BrandsLink} from './BrandsLink';
import {styles} from './StyleCurator.styles';
import {TEXT, IMAGE} from './StyleCurator.definition';
import {IDS} from '../../PSIntro.definition';
import {Image} from '@/components/atoms';
import {useHomeContext} from '@/pages/Home/context';

export const StyleCuratorIntro: React.FC = () => {
  const {formatMessage} = useIntl();
  const {screen} = useDevice();

  const {productShowcase} = useHomeContext();
  const productShowcaseEnabled = productShowcase.variant !== undefined;
  const title = productShowcaseEnabled ? TEXT.EXP_TITLE : TEXT.TITLE;

  return (
    <View style={[styles.container, !screen.L && styles.containerS]}>
      <View style={styles.content}>
        <TitleIcon color={COLOR.TEXT_LIGHT} text={formatMessage(TEXT.HEADER)} icon="double_rectangle" />

        <Text
          nativeID={IDS.TITLE}
          as="h2"
          heading
          level={2}
          style={styles.title}
          marginBottom={SPACE.SPACE_6}
          marginTop={{L: SPACE.SPACE_2, default: SPACE.SPACE_4}}
        >
          {formatMessage(title)}
        </Text>

        <Text as="p" level={2} style={styles.description}>
          {formatMessage(TEXT.BODY)}
        </Text>

        <BrandsLink />
      </View>
      <View style={styles.imageContainer}>
        <Image width={576} height={720} src={IMAGE} lazyLoad animation="slide" imageStyle={styles.image} />
      </View>
    </View>
  );
};
