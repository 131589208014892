import React from 'react';
import {COLOR} from '@lookiero/aurora';

import {Section} from '@/components/atoms/Section';
import {IDS} from './PSIntro.definition';
import {StyleCuratorIntro, StyleCuratorContent} from './components';

const PsIntro: React.FC = () => {
  return (
    <Section nativeID={IDS.SECTION} backgroundColor={COLOR.BG_ROSE}>
      <StyleCuratorIntro />
      <StyleCuratorContent />
    </Section>
  );
};

const MemoizedPsIntro = React.memo(PsIntro);
export {MemoizedPsIntro as PsIntro};
