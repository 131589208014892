import React from 'react';

import {COLOR, SPACE, Text, TitleIcon, useDevice, View} from '@lookiero/aurora';
import {Image} from '@/components/atoms';
import {useIntl} from '@/modules/I18N';
import {getImages, IDS, TEXT} from './AboutCost.definition';
import {styles} from './AboutCost.styles';
import {Section} from '@/components/atoms/Section';
import {useHomeContext} from '@/pages/Home/context';
import {useMendelContext} from '@/modules/ABTesting';

const AboutCost: React.FC = () => {
  const {screen} = useDevice();
  const {formatMessage} = useIntl();
  const {onLoadContent} = useHomeContext();

  const {freeTrial} = useMendelContext();
  const expText = freeTrial.text?.aboutCost;
  const quote2 = expText ? {id: expText} : TEXT.QUOTE_2;

  return (
    <Section nativeID={IDS.SECTION} backgroundColor={COLOR.BG_SUCCESS}>
      <View style={[styles.container, !screen.L && styles.containerS]}>
        <View style={styles.media} nativeID={IDS.IMAGE}>
          <Image
            imageStyle={styles.image}
            height={469}
            width={375}
            src={getImages(screen)}
            onLoad={onLoadContent}
            lazyLoad
            animation="slide"
          />
        </View>
        <View style={styles.content}>
          <TitleIcon text={formatMessage(TEXT.TITLE)} icon={'rectangle'} />
          <Text
            as="h2"
            heading
            level={screen.L ? 2 : 3}
            marginTop={{L: SPACE.SPACE_2, default: SPACE.SPACE_4}}
            nativeID={IDS.TITLE}
          >
            {formatMessage(TEXT.SUBTITLE)}
          </Text>
          <Text as="p" level={2} marginTop={{L: SPACE.SPACE_8, default: SPACE.SPACE_4}}>
            {formatMessage(TEXT.QUOTE_1)}
          </Text>
          <Text as="p" level={2} marginTop={{L: SPACE.SPACE_8, default: SPACE.SPACE_4}}>
            {formatMessage(quote2)}
          </Text>
        </View>
      </View>
    </Section>
  );
};

const MemoizedAboutCost = React.memo(AboutCost);
export {MemoizedAboutCost as AboutCost};
