import React from 'react';

import {SPACE, Text, useDevice, View, FLEX_DIRECTION, TitleIcon} from '@lookiero/aurora';
import {useIntl} from '@/modules/I18N';
import {IDS, TEXT} from './AboutYourLookiero.definition';
import {styles} from './AboutYourLookiero.styles';
import {Section} from '@/components/atoms/Section';
import {BulletView} from './BulletView';

const AboutYourLookiero: React.FC = () => {
  const {screen} = useDevice();
  const {formatMessage} = useIntl();

  return (
    <Section nativeID={IDS.SECTION}>
      <TitleIcon text={formatMessage(TEXT.HEADER)} icon="double_semicircle" />

      <Text heading level={screen.L ? 2 : 3} marginTop={{L: SPACE.SPACE_2, default: SPACE.SPACE_4}}>
        {formatMessage(TEXT.TITLE)}
      </Text>

      <View
        marginTop={{L: SPACE.SPACE_12, default: SPACE.SPACE_8}}
        flexDirection={screen.L ? FLEX_DIRECTION.ROW : FLEX_DIRECTION.COLUMN}
        style={styles.bullets}
      >
        <BulletView
          icon="heart"
          title={formatMessage(TEXT.BULLET1_TITLE)}
          description={formatMessage(TEXT.BULLET1_TEXT)}
          style={styles.bullet}
        />
        <BulletView
          icon="style"
          title={formatMessage(TEXT.BULLET2_TITLE)}
          description={formatMessage(TEXT.BULLET2_TEXT)}
          style={styles.bullet}
        />
        <BulletView
          icon="zap"
          title={formatMessage(TEXT.BULLET3_TITLE)}
          description={formatMessage(TEXT.BULLET3_TEXT)}
          style={styles.bullet}
        />
      </View>
    </Section>
  );
};

const MemoizedAboutYourLookiero = React.memo(AboutYourLookiero);
export {MemoizedAboutYourLookiero as AboutYourLookiero};
