import React from 'react';
import {COLOR, Icon, IconName, SPACE, Text, View, ViewProperties} from '@lookiero/aurora';
import {styles} from './BulletView.styles';

interface BulletViewProps extends ViewProperties {
  icon: IconName;
  title: string;
  description: string;
}

export const BulletView: React.FC<BulletViewProps> = ({icon, title, description, ...rest}) => {
  return (
    <View {...rest}>
      <View marginBottom={SPACE.SPACE_4}>
        <View style={styles.icon}>
          <Icon name={icon} />
        </View>
      </View>
      <Text as="h3" action level={2} marginBottom={SPACE.SPACE_1}>
        {title}
      </Text>
      <Text as="p" level={3} color={COLOR.TEXT_MEDIUM}>
        {description}
      </Text>
    </View>
  );
};
