import {retrieveIntroConfig} from '../config/introConfig';
import {getSiteConfig} from '@/modules/Site';

const {country} = getSiteConfig();
const {content, images} = retrieveIntroConfig(country);

export const getIntroConfig = () => {
  return {
    content,
    images,
  };
};
