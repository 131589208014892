import React, {FC} from 'react';
import {ALIGN, FLEX_DIRECTION, SPACE, Text, useDevice, View, ViewProperties} from '@lookiero/aurora';
import {Image} from '@/components/atoms';
import {styles, PS_IMAGE_SIZE} from './QuoteWithImage.styles';
import {ImageSrc} from '@/components/atoms/Image/Image';

interface QuoteWithImageProps extends ViewProperties {
  psName: string;
  psRole: string;
  psImage: string;
  psMessage: string;
  headerImage: ImageSrc;
}

export const QuoteWithImage: FC<QuoteWithImageProps> = ({psMessage, psName, psRole, psImage, headerImage, ...rest}) => {
  const {screen} = useDevice();

  return (
    <View {...rest}>
      <View>
        <Image
          imageStyle={styles.imageHeader}
          height={301}
          width={286}
          src={headerImage}
          lazyLoad
          animation={screen.L ? 'slide' : 'simple'}
        />
      </View>
      <View style={styles.quote}>
        <View flexDirection={FLEX_DIRECTION.ROW} alignItems={ALIGN.CENTER}>
          <View style={styles.psImage} marginRight={SPACE.SPACE_4}>
            <Image height={PS_IMAGE_SIZE} width={PS_IMAGE_SIZE} src={{default: psImage}} lazyLoad />
          </View>
          <View style={styles.title}>
            <Text action level={2}>
              {psName}
            </Text>
            <Text detail level={1}>
              {psRole}
            </Text>
          </View>
        </View>
        <Text level={3} marginTop={SPACE.SPACE_2}>
          {psMessage}
        </Text>
      </View>
    </View>
  );
};
