import StyleSheet from 'react-native-extended-stylesheet';

export const styles = StyleSheet.create({
  bullets: {
    gap: '$space8',
  },
  bullet: {
    flex: 1,
  },
});
