import {IMAGES_URL} from '@/config';
import {ImageSource} from '@/components/atoms';

const ASSET_URL = `${IMAGES_URL}/personal-shopper-intro/rebrand`;
export const IMAGE: ImageSource = {
  avif: `${ASSET_URL}/style-curator@2x.avif`,
  webp: `${ASSET_URL}/style-curator@2x.webp`,
  default: `${ASSET_URL}/style-curator@2x.png`,
};

export const TEXT = {
  HEADER: {id: 'home.stylecurators.header'},
  TITLE: {id: 'home.stylecurators.title'},
  EXP_TITLE: {id: 'exp_home.stylecurators.title'},
  BODY: {id: 'home.stylecurators.body'},
  LINK_TEXT: {id: 'home.stylecurators.linktext'},
  LINK: {id: 'home.stylecurators.link'},
} as const;
