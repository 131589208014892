import React, {CSSProperties} from 'react';
import StyleSheet from 'react-native-extended-stylesheet';
import {View, useDevice} from '@lookiero/aurora';
import {ASSETS_URL} from '@/config';
import {Video} from '@/components/atoms/Video';

const VIDEO = {
  desktop: `${ASSETS_URL}/videos/q1swap/Q1_Swap_desktop.mp4`,
  mobile: `${ASSETS_URL}/videos/q1swap/Q1_Swap_mobile.mp4`,
};

const styles = StyleSheet.create({
  bottomOverlap: {
    height: '$space4',
    borderTopLeftRadius: '$space4',
    borderTopRightRadius: '$space4',
    marginTop: '$space3 * -1',
    backgroundColor: '$colorBgBase',
  },
});

const desktopSize = {width: 450, height: 562};
const mobileSize = {width: 375, height: 245};

export const QuizVideo: React.FC<{style?: CSSProperties}> = ({style}) => {
  const {screen} = useDevice();

  const src = screen.L ? VIDEO.desktop : VIDEO.mobile;
  const size = screen.L ? desktopSize : mobileSize;
  const lazyAnimation = !screen.L ? 'simple' : 'slide';

  return (
    <>
      <Video src={src} {...size} style={style} lazyLoad animation={lazyAnimation} />
      {!screen.L && <View style={styles.bottomOverlap}></View>}
    </>
  );
};
