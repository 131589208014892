import Cookies from 'js-cookie';
import type {CookieAttributes} from 'js-cookie';

const cookie = {
  set: (name: string, value: string, options: CookieAttributes): void => Cookies.set(name, value, options),
  get: (name: string): string | undefined => Cookies.get(name),
  remove: (name: string, options?: CookieAttributes) => Cookies.remove(name, options),
};

export {cookie};
