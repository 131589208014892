import StyleSheet from 'react-native-extended-stylesheet';

export const styles = StyleSheet.create({
  modalMobileNotVisible: {
    top: '10%',
  },
  modalMobileVisible: {
    top: 0,
  },
  content: {
    display: 'grid',
    columnGap: '$space2',
    rowGap: '$space2',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    gridTemplateColumns: 'repeat(3, max-content)',
  },
  img: {
    width: '98px',
    height: '66px',
    marginTop: '0',
    marginRight: 'auto',
  },
});
