import {Country} from '@/types/Country';
import {IMAGES_URL} from '@/config';

const ASSET_TRUST_PILOT_URL = `${IMAGES_URL}/intro/trustpilot`;

const LOGO = {
  name: 'Logo',
  source: {
    default: `${ASSET_TRUST_PILOT_URL}/logo.svg`,
  },
};
const STARS = {
  name: 'Stars',
  source: {
    default: `${ASSET_TRUST_PILOT_URL}/stars.svg`,
  },
};

const TRUST_PILOT_COUNTRIES: Country[] = [];

const getTrustPilotImageBySite = (countryActive: Country) => {
  return TRUST_PILOT_COUNTRIES.includes(countryActive) ? {logo: LOGO, stars: STARS} : undefined;
};

export const retrieveIntroConfig = (countryActive: Country) => {
  return {
    content: {
      bullets: [
        {
          defaultMessage: 'Receive 5 curated items handpicked by your personal shopper',
          id: 'home.main.bullet1',
        },
        {defaultMessage: 'Only pay for what you keep', id: 'home.main.bullet2'},
        {defaultMessage: 'Free shipping and returns on all items', id: 'home.main.bullet3'},
      ],
      cta: {defaultMessage: 'Discover yours', id: 'home.main.cta'},
      description: {
        defaultMessage: 'Receive five pieces handpicked for you by your Personal Shopper.',
        id: 'home.main.subtitle',
      },
      title: {defaultMessage: 'Style inpired by you', id: 'home.main.title'},
      trustpilot: {defaultMessage: 'Rated great with over 1700 reviews', id: 'home.main.trustpilot'},
    },
    images: {
      trustpilot: getTrustPilotImageBySite(countryActive),
    },
  };
};
