export const IDS = {
  SECTION: 'home-intro',
  TITLE: 'home-intro-title',
  BULLETS: 'home-intro-bullets',
  IMAGE: 'home-intro-image',
  CTA: 'HomeCtaAtf',
} as const;

export const TEXT = {
  TITLE: {id: 'exp.rebrandclaims.title_v1'},
} as const;
