import {useMemo, useCallback, useState, useEffect} from 'react';
import {SessionStorage} from '@/utils/sessionStorage';

const STORAGE_KEY = 'grw_quizOptions';

export const useCheckList = () => {
  const [values, setValues] = useState<string[]>([]);

  useEffect(() => {
    const storedValues = SessionStorage.get(STORAGE_KEY);
    if (storedValues) setValues(JSON.parse(storedValues));
  }, []);

  useEffect(() => {
    SessionStorage.set(STORAGE_KEY, JSON.stringify(values));
  }, [values]);

  const toggle = useCallback(
    (key: string) => {
      let checked;

      if (values.includes(key)) {
        checked = false;
        setValues(current => current.filter(value => value !== key));
      } else {
        checked = true;
        setValues(current => [...current, key]);
      }

      return checked;
    },
    [values],
  );

  const isChecked = useCallback((key: string) => values.includes(key), [values]);

  return useMemo(() => ({values, toggle, isChecked}), [values, toggle, isChecked]);
};
