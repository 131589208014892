import React, {Suspense} from 'react';
import {Spinner} from '@lookiero/aurora';
import {QUIZ_ID} from './services/scrollToQuiz';
import {useQuizAutoScroll} from './hooks/useQuizAutoScroll';

const MIN_HEIGHT_WRAPPER = 400;
const Quiz = React.lazy(() => import('./Quiz'));

const LazyQuiz: React.FC<{renderQuiz?: boolean}> = ({renderQuiz = true}) => {
  useQuizAutoScroll();

  return (
    <div id={QUIZ_ID} style={{minHeight: MIN_HEIGHT_WRAPPER, alignContent: 'center'}}>
      <Suspense fallback={<Spinner />}>{renderQuiz && <Quiz />}</Suspense>
    </div>
  );
};

export {LazyQuiz};
