import {useMemo} from 'react';
import {useMendel} from '@/modules/ABTesting';
import {experiments} from '@/modules/ABTesting/mendel/config/Experiments.definition';

const {GRW_PRODUCT_SHOWCASE} = experiments;

export interface ProductShowcaseResult {
  isLoading: boolean;
  variant: 'v1' | 'v2' | undefined;
}

export const useProductShowcase = (): ProductShowcaseResult => {
  const {isLoading, variant} = useMendel({name: GRW_PRODUCT_SHOWCASE.name});

  const result = useMemo(() => {
    if (variant === GRW_PRODUCT_SHOWCASE.variants.v1) return 'v1';
    if (variant === GRW_PRODUCT_SHOWCASE.variants.v2) return 'v2';
    return undefined;
  }, [variant]);

  return useMemo(() => ({isLoading, variant: result}), [isLoading, result]);
};
