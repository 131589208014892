import React, {FC} from 'react';
import {SPACE, Text, useDevice, View, ViewProperties, COLOR} from '@lookiero/aurora';
import {Video} from '@/components/atoms/Video';

import {styles} from './QuoteVideo.styles';

interface QuoteVideoProps extends ViewProperties {
  src: string;
  title: string;
  description: string;
}

export const QuoteVideo: FC<QuoteVideoProps> = ({src, title, description, ...rest}) => {
  const {screen} = useDevice();

  const isDesktop = screen.L;
  const lazyAnimation = !isDesktop ? 'simple' : 'slide';

  return (
    <View {...rest}>
      <View>
        <Video src={src} width={286} height={300} style={styles.video} lazyLoad animation={lazyAnimation} />
      </View>
      <View style={styles.quote}>
        <Text action level={2}>
          {title}
        </Text>
        <Text level={3} marginTop={SPACE.SPACE_2} color={COLOR.TEXT_MEDIUM}>
          {description}
        </Text>
      </View>
    </View>
  );
};
