export const IDS = {
  SECTION: 'how',
} as const;

export const TEXT = {
  TITLE: {id: 'home.how.header', defaultMessage: 'How it works'},
  SUBTITLE: {
    id: 'home.how.subtitle',
    defaultMessage: 'Start your style journey with three simple steps',
  },
} as const;
