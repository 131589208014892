import React, {FC} from 'react';
import {ALIGN, COLOR, DISPLAY, FLEX_DIRECTION, SPACE, Text, View} from '@lookiero/aurora';

import {useIntl} from '@/modules/I18N';
import {Image} from '@/components/atoms';
import {useHomeContext} from '@/pages/Home/context';
import {styles} from './TrustPilot.styles';
import {getIntroConfig} from '../../services/getIntroConfig';

const {content, images} = getIntroConfig();

export const TrustPilot: FC = () => {
  const {formatMessage} = useIntl();
  const {onLoadContent} = useHomeContext();

  return (
    <>
      {images.trustpilot && (
        <View
          style={styles.trustpilotWrapper}
          display={DISPLAY.FLEX}
          paddingVertical={SPACE.SPACE_8}
          textAlign={ALIGN.LEFT}
        >
          <Text
            as="p"
            detail
            level={1}
            color={COLOR.GRAYSCALE_XL}
            style={styles.trustpilotText}
            marginBottom={SPACE.SPACE_05}
            marginTop={SPACE.SPACE_2}
            marginRight={SPACE.SPACE_4}
          >
            {formatMessage(content.trustpilot)}
          </Text>

          <View
            alignItems={ALIGN.CENTER}
            style={styles.trustpilotImage}
            display={DISPLAY.FLEX}
            flexDirection={FLEX_DIRECTION.COLUMN}
            justifyContent={ALIGN.BETWEEN}
          >
            <Image
              alt={images.trustpilot.logo.name}
              height={24}
              width={98}
              onLoad={onLoadContent}
              src={images.trustpilot.logo.source}
            />
            <Image
              alt={images.trustpilot.stars.name}
              height={18}
              width={98}
              onLoad={onLoadContent}
              src={images.trustpilot.stars.source}
            />
          </View>
        </View>
      )}
    </>
  );
};
