export const IDS = {
  SECTION: 'faq',
} as const;

export const TEXT = {
  TITLE: {id: 'exp_faqshp_header'},
  SUBTITLE: {id: 'exp_faqshp_title'},
  PS_TITLE: {id: 'exp_faqshp.ps.title'},
  PS_TEXT: {id: 'exp_faqshp.ps.text'},
  PRICE_TITLE: {id: 'exp_faqshp.price.title'},
  PRICE_TEXT_FREE: {id: 'exp_faqshp.price.text_free'},
  PRICE_TEXT_NOT_FREE: {id: 'exp_faqshp.price.text_notfree'},
  SIZES_TITLE: {id: 'exp_faqshp.sizes.title'},
  SIZES_TEXT: {id: 'exp_faqshp.sizes.text'},
  PREVIEW_TITLE: {id: 'exp_faqshp.preview.title'},
  PREVIEW_TEXT: {id: 'exp_faqshp.preview.text'},
  TIME_TITLE: {id: 'exp_faqshp.time.title'},
  TIME_TEXT: {id: 'exp_faqshp.time.text'},
  SHIPPING_TITLE: {id: 'exp_faqshp.shipping.title'},
  SHIPPING_TEXT: {id: 'exp_faqshp.shipping.text'},
};
