import React from 'react';
import {useHomeContext} from '@/pages/Home/context';

import {AboutYourLookiero as Control} from './AboutYourLookiero';
import {PainSlider} from './PainSlider';

const Factory: React.FC = () => {
  const {painSlider} = useHomeContext();

  if (painSlider.variant === 'v1') return <PainSlider />;

  return <Control />;
};

export {Factory};
