import {useEffect, useMemo, useState} from 'react';
import {useInView, IntersectionOptions} from 'react-intersection-observer';

const DEFAULT_CONFIG: IntersectionOptions = {
  fallbackInView: true,
} as const;

interface LazyLoadResult {
  ref: (node?: Element | null | undefined) => void;
  inView: boolean;
  firstView: boolean;
}

export const useIntersectionObserver = (props?: IntersectionOptions): LazyLoadResult => {
  const {ref, inView} = useInView({...DEFAULT_CONFIG, ...props});

  const [firstView, setFirstView] = useState(inView);
  useEffect(() => {
    if (inView) setFirstView(true);
  }, [inView]);

  return useMemo(() => ({ref, inView, firstView}), [ref, inView, firstView]);
};
