import React, {useEffect, useState} from 'react';
import {useDevice} from '@lookiero/aurora';
import styles from './ScrollView.module.css';
import {eventManager, UI_EVENT} from '@/modules/EventManager';

const ScrollView: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    if (scrolled) {
      eventManager.emit(UI_EVENT.STYLE_CURATOR_SLIDER);
    }
  }, [scrolled]);

  const {screen} = useDevice();
  const margin = screen.M ? styles.marginMedium : styles.marginSmall;

  return (
    <div className={`${styles.scroll} ${margin}`} onScroll={() => setScrolled(true)}>
      {children}
    </div>
  );
};

export {ScrollView};
