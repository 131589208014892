import React from 'react';
import {LazyQuiz as Control} from './lazy';
import {Q1Swap, useQ1Swap} from './Q1Swap';

const QuizFactory: React.FC<{renderQuiz?: boolean}> = ({renderQuiz = true}) => {
  const {isLoading, variant} = useQ1Swap();

  if (isLoading) return <></>;
  if (variant === 'v1') return <Q1Swap />;
  return <Control renderQuiz={renderQuiz} />;
};

export {QuizFactory};
