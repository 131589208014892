import React from 'react';
import {useDevice, View} from '@lookiero/aurora';
import {useIntl} from '@/modules/I18N';
import {Quote} from '@/pages/Home/components/PsIntro/components';
import {styles} from './StyleCuratorContent.styles';
import {PS_IMAGES, TEXT} from './StyleCuratorContent.definition';

const StyleCuratorContent: React.FC = () => {
  const {screen} = useDevice();
  const {formatMessage} = useIntl();

  const quotesContainerStyle = screen.L ? styles.quotesContainerL : styles.quotesContainerS;

  return (
    <View style={[styles.quotesContainer, quotesContainerStyle]}>
      <Quote
        style={styles.quote}
        message={formatMessage(TEXT.QUOTE1_MESSAGE)}
        personName={formatMessage(TEXT.QUOTE1_PS)}
        personRole={formatMessage(TEXT.QUOTE_ROL)}
        imageUrl={PS_IMAGES.IMAGE_1}
      />
      <Quote
        style={styles.quote}
        message={formatMessage(TEXT.QUOTE2_MESSAGE)}
        personName={formatMessage(TEXT.QUOTE2_PS)}
        personRole={formatMessage(TEXT.QUOTE_ROL)}
        imageUrl={PS_IMAGES.IMAGE_2}
      />
      <Quote
        style={styles.quote}
        message={formatMessage(TEXT.QUOTE3_MESSAGE)}
        personName={formatMessage(TEXT.QUOTE3_PS)}
        personRole={formatMessage(TEXT.QUOTE_ROL)}
        imageUrl={PS_IMAGES.IMAGE_3}
      />
    </View>
  );
};

export {StyleCuratorContent};
