import type {Step} from '@/components/atoms';

export const HOW_IT_WORK_CONFIG: Partial<Step>[] = [
  {
    title: {
      defaultMessage: 'Your style, your budget',
      id: 'home.how.step1_title',
    },
    summary: {
      defaultMessage:
        'Complete your profile and a Personal Shopper will select the clothes that best suit your style and budget.',
      id: 'home.how.step1_paragraph',
    },
  },
  {
    title: {
      defaultMessage: 'Receive a box with 5 garments',
      id: 'home.how.step2_title',
    },
    summary: {
      defaultMessage: "We will send you, free of shipping costs, your Personal Shopper's clothing selection.",
      id: 'home.how.step2_paragraph',
    },
  },
  {
    title: {
      defaultMessage: 'Try everything on at home and pay later',
      id: 'home.how.step3_title',
    },
    summary: {
      defaultMessage:
        'Try on the clothes comfortably at home, only pay for the ones you decide to keep and return the rest for free.',
      id: 'home.how.step3_paragraph',
    },
  },
];
